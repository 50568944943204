import { openModal } from '@carwow/carwow_theme/app/javascript/components/modal'

function setEmailValueOnCopy (email, emailInput) {
  if (email) {
    return
  }

  const emailCopyInput = document.querySelector('.login-magic-link-modal .email-copy')
  emailCopyInput.innerHTML = emailCopyInput.innerHTML.replace(/<strong><\/strong>/, `<strong>${emailInput.value}</strong>`)
}

function requestMagicEmail (email, url) {
  const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content

  fetch(
    url,
    {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        email
      })
    }
  )
}

function addMagicEmailClickHandler (selector) {
  const element = document.querySelector(selector)
  if (!element) return

  element.addEventListener('click', event => {
    const email = event.target.dataset.email
    const emailInput = document.querySelector('input[type="email"]')

    setEmailValueOnCopy(email, emailInput)

    if (!email && emailInput) {
      const form = emailInput.closest('form')

      if (form.checkValidity() === false) {
        form.reportValidity()
        return
      }
    }

    const url = event.target.dataset.url

    requestMagicEmail(email || emailInput.value, url)

    openModal('.login-magic-link-modal')
  })
}

addMagicEmailClickHandler('.login-magic-link-trigger')
addMagicEmailClickHandler('.login-magic-link-resend')
